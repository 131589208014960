import { render, staticRenderFns } from "./TicketCollapse.vue?vue&type=template&id=427f5906&scoped=true&"
import script from "./TicketCollapse.vue?vue&type=script&lang=ts&"
export * from "./TicketCollapse.vue?vue&type=script&lang=ts&"
import style0 from "./TicketCollapse.vue?vue&type=style&index=0&id=427f5906&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427f5906",
  null
  
)

export default component.exports