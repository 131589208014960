






import TrackingClient, { GTMEvent } from '@openticket/lib-tracking';
import {
    ApiObject,
    LoadOptions,
    RudderAnalytics,
} from '@rudderstack/analytics-js';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Provide, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import {
    CoverGeniusEventTrackData,
    CoverGeniusRequestEventTrackData,
    CoverGeniusResponseEventTrackData,
} from '@/pages/shop/modules/globalProducts/CoverGenius.vue';
import { CookiePreferences } from '../../composables/cookies';
import { LogLevel } from '@openticket/sdk-shop';

@Component
export default class ShopTracking extends Vue {
    @Provide('tracking')
    client = new TrackingClient();

    rudderAnalytics = new RudderAnalytics();
    rudderstackTraits = {
        context: {
            traits: {
                shop_id: this.$shop.data.guid,
            },
        },
    };

    async created(): Promise<void> {
        try {
            // Wait for SDK to be initialized
            await this.$shop.initialized;

            // Add whitelabel GTM container
            if (this.$whitelabel.shop.gtm_code) {
                void this.client.addGTMContainer(
                    this.$whitelabel.shop.gtm_code
                );
            }

            if (
                this.$whitelabel.shop.rudderstack_data_plane_url &&
                this.$whitelabel.shop.rudderstack_write_key
            ) {
                void this.initRudderstack(
                    this.$whitelabel.shop.rudderstack_write_key,
                    this.$whitelabel.shop.rudderstack_data_plane_url,
                    this.$whitelabel.shop.rudderstack_cdn_url,
                    this.$whitelabel.shop.rudderstack_config_url
                );
            }

            this.$cookies.onChange(
                (newPreferences: Partial<CookiePreferences>) => {
                    this.cookiePreferencesUpdated(
                        newPreferences,
                        this.$cookies.getComparableState()
                    );

                    this.$shop.cart.setTechData({
                        cookies: this.$cookies.getComparableState(),
                    });
                }
            );

            // Triggered on covergenius events
            this.$shop.events.on(
                ['covergenius'],
                (
                    path: string[],
                    data:
                        | CoverGeniusEventTrackData
                        | CoverGeniusRequestEventTrackData
                        | CoverGeniusResponseEventTrackData
                ) => {
                    if (path.length > 1) {
                        const joinedPath = path.join('-');
                        switch (joinedPath) {
                            case 'covergenius-quote-requested':
                            case 'covergenius-quote-received':
                            case 'covergenius-product-selected':
                            case 'covergenius-product-deselected':
                                this.trackRudderstack(joinedPath, data);
                                break;
                            case 'covergenius-quote-rejected':
                                this.trackRudderstack(joinedPath);
                                break;
                            default:
                                // don't track
                                break;
                        }
                    }
                }
            );

            // Triggered on date selection in timeslot shop
            this.$shop.events.on(
                ['shop', 'impression', 'filter', 'events'],
                (_, data: any) => {
                    this.client.push(
                        GTMEvent.impression(data.shop, data.items)
                    );
                    // skip for now (determine data model to send)
                    // this.trackRudderstack('impression', data.items );
                }
            );

            // Triggered on reserving a ticket
            this.$shop.events.on(['cart'], (path: string[], data: any) => {
                if (path[3] === 'add') {
                    this.client.push([
                        GTMEvent.cartEvent('add', 'products', data),
                        GTMEvent.interaction(
                            window.location.pathname,
                            'Reserve',
                            data.name,
                            data
                        ),
                    ]);
                    this.trackRudderstack('cart-add', {
                        count: data.count,
                        name: data.name,
                        guid: data.guid,
                    });
                } else if (path[3] === 'remove') {
                    this.client.push([
                        GTMEvent.cartEvent('remove', 'products', data),
                        GTMEvent.interaction(
                            window.location.pathname,
                            'Release',
                            data.name,
                            data
                        ),
                    ]);
                    this.trackRudderstack('cart-remove', {
                        count: data.count,
                        name: data.name,
                        guid: data.guid,
                    });
                } else if (path[1] === 'order' && path[2] === 'placed') {
                    this.trackRudderstack('order_submitted', {
                        order_id: data.order_id,
                        shop_id: this.$shop.data.guid,
                    });
                }
            });

            // Payment related Listeners
            this.$shop.events.on(
                ['cart', 'payment_method', 'set'],
                (path: string[], data: unknown) => {
                    if (
                        data &&
                        typeof data === 'object' &&
                        'guid' in data &&
                        typeof data.guid === 'string' &&
                        data.guid
                    ) {
                        this.trackRudderstack('payment_method_set', {
                            payment_method_id: data.guid,
                        });
                    }
                }
            );

            this.$shop.events.on(
                ['cse', 'surcharge', 'response'],
                (path: string[], data: unknown) => {
                    if (data && typeof data === 'object') {
                        let lastPath = path[path.length - 1];
                        this.trackRudderstack('surcharge_' + lastPath, data);
                    }
                }
            );

            this.$shop.events.on(
                ['cse', 'cardDetails'],
                (path: string[], data: unknown) => {
                    if (data && typeof data === 'object') {
                        let errName = path[path.length - 1];
                        this.trackRudderstack('surcharge_carddetails_error', {
                            error_name: errName,
                            ...data,
                        });
                    }
                }
            );

            // Initial page load event
            this.pushRouteChange(this.$route, this.$route, {
                events: this.$shop.data.events.map(event => ({
                    guid: event.guid,
                    name: event.name,
                })),
            });
        } catch (e) {
            // TODO: log when loggin levels are introduced
            console.warn(e);
        }
    }

    cookiePreferencesUpdated(
        preferences: Partial<CookiePreferences>,
        comparableState: string
    ): void {
        try {
            this.$shop.log.create(
                LogLevel.Info,
                ['shop', 'cookies', 'debug', 'message'],
                { preferences, comparableState }
            );
        } catch (e) {
            console.error(
                'Failed to log cookies debug message',
                e,
                comparableState
            );
        }

        try {
            // Cookie preferences can be updated when:
            // - Saved preferences are retrieved throught the cookie client;
            // - The user has manually updated (a subset of) cookie preferences;
            // - A host app has manually specified the values;

            // The preferences can be individually present in the preferences object.
            // Personalised ads will only be accepted when both the base ads and
            // personalized ads properties are set to true.
            if (!preferences) {
                this.client.revokeConsent();

                return;
            }

            // When organiserMarketing is accepted and its shop has a GTM code,
            // the shop specific GTM container is loaded.
            //
            // DD-SHOP-2714
            //
            // The addGTM method can be triggered as often as convenient.
            // The method will silently ignore GTM codes which were already succesfully registered.
            const { google_tag } = this.$shop.data;

            if (preferences.organiserMarketing && google_tag) {
                this.client.addGTMContainer(google_tag);
            }

            this.client.updateConsent(preferences);
        } catch (e) {
            this.$shop.log.create(
                LogLevel.Error,
                ['shop', 'cookies', 'failed', 'update'],
                { preferences, comparableState },
                undefined,
                e
            );
        }
    }

    /**
     * Triggered on route changes and on the initial page load
     */
    pushRouteChange(
        to: Route,
        from: Route,
        extra?: Record<string, unknown>
    ): void {
        try {
            // Deduce page title from current route
            let page: string;
            if (to.params.page) {
                page = to.params.page;
            } else {
                page = to.name || to.path;
            }

            // Add shop specific metadata
            const data = {
                ...extra,
                shopPage: page,
                shopName: this.$shop.data.name,
                shopGuid: this.$shop.data.guid,
            };

            // Push to tracking client
            this.client.push([
                GTMEvent.contentView(window.location.pathname, data),
                GTMEvent.history(to.fullPath, from.fullPath),
            ]);

            // Only trigger actual page switches, not change in GET params
            if (to.path !== from.path) {
                this.rudderAnalytics.page(
                    'shop',
                    page,
                    {
                        to: to.fullPath,
                        from: from.fullPath,
                    },
                    this.rudderstackTraits
                );
            }
        } catch (e) {
            // TODO: log when logging levels are introduced
            console.warn(e);
        }
    }

    initRudderstack(
        writeKey: string,
        dataPlaneUrl: string,
        cdnUrl?: string,
        configUrl?: string
    ): void {
        try {
            const rudderstackLoadOptions: Partial<LoadOptions> = {
                sendAdblockPage: true,
                // These settings should be amended when all projects are using 3.x
                // https://www.rudderstack.com/docs/sources/event-streams/sdks/rudderstack-javascript-sdk/migration-guide/#migration-considerations
                storage: {
                    encryption: {
                        version: 'legacy',
                    },
                    migrate: false,
                },
                plugins: [
                    'GoogleLinker',
                    'NativeDestinationQueue',
                    'StorageEncryptionLegacy',
                    'StorageEncryption',
                    'StorageMigrator',
                    'DeviceModeDestinations',
                ],
            };

            if (cdnUrl) {
                rudderstackLoadOptions.pluginsSDKBaseURL = cdnUrl;
            }
            if (configUrl) {
                rudderstackLoadOptions.configUrl = configUrl;
            }

            this.rudderAnalytics.load(
                writeKey,
                dataPlaneUrl,
                rudderstackLoadOptions
            );

            // Make sure to remove anonymous_id from url when present
            this.rudderAnalytics.ready(() => {
                this.$router.replace({
                    query: { ...this.$route.query, ajs_aid: undefined },
                });
            });

            // Make sure to reset all traits and start clean (only leave anonymousId intact)
            this.rudderAnalytics.reset();

            // Set new trait with current shop_id
            this.rudderAnalytics.page(
                'shop',
                'init',
                {},
                this.rudderstackTraits
            );
        } catch (e) {
            // Tracking should never block flow
        }
    }

    trackRudderstack(
        event: string,
        data?:
            | CoverGeniusEventTrackData
            | CoverGeniusRequestEventTrackData
            | CoverGeniusResponseEventTrackData
            | ApiObject
    ): void {
        this.rudderAnalytics.track(
            event,
            data as ApiObject,
            this.rudderstackTraits
        );
    }

    @Watch('$route')
    async onRouteChanged(to: Route, from: Route): Promise<void> {
        // Notify shop sdk, only after user invoked router change
        if (from.params.page) {
            this.$shop.events.emit(['router'], {
                new: to.fullPath,
                old: from.fullPath,
            });
        }

        await this.$shop.initialized;
        this.pushRouteChange(to, from);
    }
}
